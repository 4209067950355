const Catalogue = () => {
    return (
        <iframe
        src="/catalogue.html"
        title="Catalogue"
        className="w-5/6 h-11/12"
        />
    );
}

export default Catalogue;
